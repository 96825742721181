<template>
  <div style="padding-top: 12px">
    <div class="items" v-for="item in dataList" :key="item.id" @click="goInfo(item.id,item.subarea,item.scId,item.sid)">
        <div class="top">
            <div class="top-left" :class="{'isOld34': $isOld == 1}">{{item.brand}}</div>
            <div class="top-right" :class="{'isOld28': $isOld == 1,'isOld152w': $isOld == 1}" @click.stop="goIllegally(item.id)">违停记录</div>
        </div>
        <div class="down">
            <div class="plate-number" :class="item.isNewEnergy == 1 ? 'green' : item.isNewEnergy == 0 ? 'blue' : 'other'">
                <div
                    class="car-type"
                    :class="{'green-type': item.isNewEnergy == 1,
                    'blue-type': item.isNewEnergy == 0,
                    'other-type': item.isNewEnergy != 0 && item.isNewEnergy != 1,
                    'isOld28': $isOld == 1,
                    'isOld102w': $isOld == 1
                    }" >{{item.typeStr}}</div>
                <div class="car-number" :class="{'isOld40': $isOld == 1}">{{item.carNumber}}</div>
            </div>
        </div>
    </div>
    <div class="add-car">
        <div class="nothing" v-if="dataList.length < 1">
            <div class="nothing-bottom"></div>
            <div class="nothing-text">您当前暂未添加车辆</div>
        </div>
        <img :src="require('@/assets/img/newVersion/no-car.png')" alt="" class="no-car">
        <van-button class="btn" @click="goAdd(1)">
            <img class="btn-icon" :src="require('@/assets/img/newVersion/new-add.png')" alt="">
            <div class="btn-text" :class="{'isOld36': $isOld == 1}">添加车辆</div>
        </van-button>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            dataList: []
        }
    },
    created() {
        this.getDataList()
    },
    methods: {
        getDataList() {
            this.$toast.loading({ duration: 0, message: '加载中...', forbidClick: true, });
            this.$http({
                url: this.$http.adornUrl('/wxapp/devicecar/list'),
                method: 'GET',
                params: this.$http.adornParams({
                    page: 1,
                    limit: -1,
                    userId: this.$userId,
                    orgId: this.$orgId,
                    userAssistId: this.$globalData.userInfo.assistId || '',
                })
            }).then(({data}) => {
                this.$toast.clear()
                if(data && data.code === 0) {
                    this.dataList = data.page.list
                }else {
                    this.$toast.fail(data.msg)
                }
            })
        },
        goIllegally(id) {
            this.$router.push({path:'/illegallyPark',query: {id}})
        },
        goAdd() {
            this.$router.push({path:'/carAdd'})
        },
        goInfo(id,subarea,scId,sid) {
            this.$router.push({path:'/carAdd',query: {id,subarea,scId,sid}})
        },
    }

}
</script>

<style scoped lang="scss">
.isOld152w {
    width: 152px !important;
    height: 56px !important;
}
.isOld102w {
    width: 102px !important;
    height: 44px !important;
}
.items {
    width: 750px;
    height: 226px;
    background: #FFFFFF;
    padding: 0 30px;
    margin-bottom: 16px;
    .top {
        height: 90px;
        font-family: 'PingFangSC-Regular';
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        .top-left {
            font-size: 30px;
            color: #333333;
        }
        .top-right {
            width: 136px;
            height: 52px;
            background: #FFFFFF;
            border-radius: 8px;
            border: 2px solid #4581F8;
            font-size: 24px;
            color: #4581F8;
            line-height: 52px;
            text-align: center;
        }
    }
    .down {
        padding-top: 22px;
        .plate-number {
            position: relative;
            width: 512px;
            height: 88px;
            // background: rgba(223,137,30,0.1);
            border-radius: 8px;
            margin: 0 auto;
            .car-number {
                font-size: 40px;
                font-family: 'PingFangSC-Semibold';
                font-weight: 600;
                // color: #DF891E;
                line-height: 88px;
                text-align: center;
            }
            .car-type {
                position: absolute;
                top: 0;
                right: 0;
                width: 88px;
                height: 42px;
                // background: linear-gradient(135deg, #58CC64 0%, #48975F 100%);
                border-radius: 0px 8px 0px 8px;
                font-size: 24px;
                font-family: 'PingFangSC-Regular';
                font-weight: 400;
                color: #FFFFFF;
                line-height: 42px;
                text-align: center;
            }
            .green-type {
                background: linear-gradient(135deg, #58CC64 0%, #48975F 100%);
            }
            .blue-type {
                background: linear-gradient(90deg, #76A4FF 0%, #4581F8 100%);
            }
            .other-type {
                background: linear-gradient(90deg, #FFB252 0%, #DF891E 100%);
            }
        }
    }
    .green {
        background: rgba(43,143,50,0.12);
        color: #2B8F32;
    }
    .blue {
        background: rgba(69,129,248,0.12);
        color: #4581F8;
    }
    .other {
        background: rgba(223,137,30,0.1);
        color: #DF891E;
    }
}
.add-car {
    position: relative;
    width: 750px;
    height: 226px;
    background: #FFFFFF;
    box-shadow: 0px 0px 26px 0px rgba(234,234,234,0.41);
    display: flex;
    .nothing {
        position: relative;
        height: 40px;
        margin-top: 24px;
        margin-left: 30px;

        font-size: 28px;
        font-family: 'PingFangSC-Regular';
        font-weight: 400;
        color: #666666;
        .nothing-bottom {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 256px;
            height: 16px;
            background: linear-gradient(90deg, #4581F8 0%, rgba(69,129,248,0) 100%);
        }
        .nothing-text {
            height: 40px;
        }
    }
    .no-car {
        width: 440px;
        height: 198px;
        margin: 0 auto;
    }
    
    .btn {
        position: absolute;
        bottom: 24px;
        left: 120px;
        width: 512px;
        height: 80px;
        background: #4581F8;
        box-shadow: 0px 4px 22px 0px rgba(69,129,248,0.35);
        border-radius: 8px;
        
        .btn-icon {
            width: 32px;
            height: 32px;
            margin-right: 12px;
        }
        .btn-text {
            font-size: 28px;
            font-family: 'PingFangSC-Semibold';
            font-weight: 600;
            color: #FFFFFF;
        }
    }
}
</style>
<style scoped>
.van-button >>> .van-button__text {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>